import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { AccountStoreContext } from '../store/AccountStore';

const InstagramPreviewMobile = observer(() => {
    const accountStore = useContext(AccountStoreContext);

    const formatText = (text) => {
        const regex = /<b>(.*?)<\/b>/g;
        const newText = text.replace(regex, '<strong>$1</strong>');
        return { __html: newText } ;
    };

    return(
        <div style={{ width: '100%', backgroundColor: '#FFFFFF', borderRadius: 8, overflow: 'hidden' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
                <img src={accountStore.profileImage} alt="Profile" style={{ height: 40, width: 40, borderRadius: '50%', marginRight: 12 }} />
                <span style={{ fontFamily: 'nexa_boldregular', fontSize: 14 }}>
                    {accountStore.accountName}
                </span>
            </div>
            <img src={accountStore.cards[0].image} alt="Post" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
            <div style={{ display: 'flex', justifyContent: "space-between", padding: '2px' }}>
                <div style={{ display: 'flex' }}>
                    <img src="./heart.svg" alt="Like" style={{ height: 20, marginRight: 8 }} />
                    <img src="./comment.svg" alt="Comment" style={{ height: 20, marginRight: 8 }} />
                    <img src="./shareInstagram.svg" alt="Share" style={{ height: 20, marginRight: 8 }} />
                </div>
                <img src="./bookmark.svg" alt="Save" style={{ height: 20 }} />
            </div>
            <div style={{ fontFamily: 'Arial, sans-serif' }}>
                <p style={{ margin: 0, fontSize: 12 }}>
                    <strong> {accountStore.accountName} </strong>
                    <span dangerouslySetInnerHTML={formatText(accountStore.cards[0].text)} style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }} />
                </p>
                <div style={{ color: '#00376b', fontSize: 10, marginTop: 4 }}>
                    {accountStore.cards[0].hashtags}
                </div>
                <span style={{ fontSize: 10, color: 'gray' }}>
                    1 minute ago
                </span>
            </div>
        </div>
    );
});
export default InstagramPreviewMobile;