import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { AccountStoreContext } from '../store/AccountStore';

const BlogPreview = observer(() => {
    const accountStore = useContext(AccountStoreContext);

    const formatText = (text) => {
        const regex = /<b>(.*?)<\/b>/g;
        const newText = text.replace(regex, '<strong>$1</strong>');
        return { __html: newText } ;
    };

    return(
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#FFFFFF', overflow: 'hidden', padding: 2 }}>
        <div style={{ display: 'flex', alignItems: 'center', fontFamily: 'nexa_boldregular', width: '100%' }}>
            <img src={accountStore.profileImage} alt="Profile" style={{ height: 40, width: 40, borderRadius: '50%', marginRight: 12 }} />
            <span style={{ fontFamily: 'nexa_boldregular', fontSize: 14 }}>
                        {accountStore.accountName}
                    </span>
        </div>
        <h2 style={{ fontSize: 24, margin: 0 }}>
        {accountStore.cards[0].title}
        </h2>
        <img src={accountStore.cards[0].image} alt="" style={{ padding: 10, borderRadius: 20, height: 128, width: '100%', objectFit: 'cover' }} />
        <p dangerouslySetInnerHTML={formatText(accountStore.cards[0].text)} style={{ marginTop: 4, marginBottom: 4, paddingLeft: 8, paddingRight: 8 }} />
        {accountStore.cards[0].hashtags}
        </div>
    );
});
export default BlogPreview;