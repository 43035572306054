import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { AccountStoreContext } from '../store/AccountStore';

const TwitterPreview = observer(() => {
    const accountStore = useContext(AccountStoreContext);

    const formatText = (text) => {
        const regex = /<b>(.*?)<\/b>/g;
        const newText = text.replace(regex, '<strong>$1</strong>');
        return { __html: newText } ;
    };

    return(
        <div style={{ backgroundColor: '#FFFFFF', borderRadius: 8, boxShadow: '0 2px 5px rgba(0,0,0,0.1)', overflow: 'hidden', padding: 2 }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
                <img src={accountStore.profileImage} alt="Profile" style={{ height: 40, width: 40, borderRadius: '50%', marginRight: 12 }} />
                <div>
                    <span style={{ fontFamily: 'Arial, sans-serif', fontSize: 14, fontWeight: 'bold' }}>
                        {accountStore.accountName}
                    </span>
                    <span style={{ marginLeft: 8, color: '#657786', fontSize: 12 }}>
                        @{accountStore.accountName} • 1m
                    </span>
                </div>
            </div>
            <div style={{ padding: '0 12px' }}>
                <p style={{ margin: 0, fontSize: 15, color: '#14171A', lineHeight: '1.5', maxHeight: 28, overflow: 'hidden' }}>
                    <span dangerouslySetInnerHTML={formatText(accountStore.cards[0].text)} />
                </p>
                <div style={{ color: '#1DA1F2', fontSize: 14, lineHeight: 2 }}>
                    {accountStore.cards[0].hashtags}
                </div>
                {accountStore.cards[0].image && (
                <img src={accountStore.cards[0].image} alt="Tweet" style={{ width: '100%', height: 230, objectFit: 'cover', borderRadius: 20, marginTop: 4, marginBottom: 4, border: '1px solid #E1E8ED' }} />
                )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '12px', borderTop: '1px solid #E1E8ED', fontSize: 14, color: '#657786' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src="./replyTwitter.svg" alt="Reply" style={{ height: 18, marginRight: 8 }} /> 
                        0
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src="./repostTwitter.svg" alt="Retweet" style={{ height: 18, marginRight: 8 }} /> 
                        0
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src="./heart.svg" alt="Like" style={{ height: 18, marginRight: 8 }} /> 
                        0
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src="./stats.svg" alt="Stats" style={{ height: 18, marginRight: 8 }} /> 
                        0
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end', width: '20%' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src="./bookmark.svg" alt="Bookmark" style={{ height: 18, marginRight: 8 }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src="./shareTwitter.svg" alt="Share" style={{ height: 18, marginRight: 8 }} />
                    </div>
                </div>
            </div>
        </div>
    );
});
export default TwitterPreview;