import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import { AccountStoreContext } from '../store/AccountStore.js';

const ProfileForm = observer(() => {
  const accountStore = useContext(AccountStoreContext);
  const [, setProfileImageSave] = useState("");
    
  const handleImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setProfileImageSave(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  return(
    <div style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 50px)' }}>
      <div style={{ overflowX: 'hidden', display: 'flex', flexDirection: 'column', background: 'white', position: 'relative' }}>
        <div style={{ backgroundColor: 'white', border: '0px solid #FFFFFF', borderRadius: 10, height: 'calc(100% - 200px)', paddingTop: 10, paddingBottom: 20 }}>
          <div style={{ display: 'flex' }}>
            <img id="profile-pic" src={accountStore.profileImage} style={{ height: '50px', width: '50px', border: '1px solid black', borderRadius: '50%', marginRight: 20 }} alt="" />
            <input type="file" id="image-upload" style={{ display: 'none' }} onChange={handleImageUpload} />
            <label htmlFor="image-upload" id="change-profile" style={{ height: 40, width: 180, border: '1px solid black', borderRadius: 7, justifyContent: 'center', alignItems: 'center', cursor: 'pointer', display: 'flex' }}>
              <p>
                Change Profile
              </p>
            </label>
          </div>
        </div>
        <div>
          <p style={{ marginBottom: 0 }}>
            Account Name
          </p>
          <input type="text" style={{ outline: 0, borderWidth: '0 0 2px', borderColor: 'rgba(0, 0, 0, 0.0001)', boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)', height: 20, width: '100%' }} onChange={e => accountStore.setAccountName(e.target.value.replace(/\s+/g, ''))} required value={accountStore.accountName} disabled={accountStore.selectedAccountData instanceof Object ? true : false} />
        </div>
        <div>
          <p style={{ marginBottom: 0 }}>
            Name Company Website
          </p>
          <input type="text" onChange={e => accountStore.setWebsite(e.target.value)} style={{ outline: 0, borderWidth: '0 0 2px', borderColor: 'rgba(0, 0, 0, 0.0001)', boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)', height: 20, width: '100%' }} required value={accountStore.website} />
        </div>
        <div>
          <p style={{ marginBottom: 0 }}>
            Email
          </p>
          <input type="text" style={{ outline: 0, borderWidth: '0 0 2px', borderColor: 'rgba(0, 0, 0, 0.0001)', boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)', height: 20, width: '100%' }}
            required value={accountStore.email}
            onChange={(e) => {
              accountStore.setEmail(e.target.value);
            }}/>
        </div>
        <div>
          <p style={{ marginBottom: 0 }}>
            Phone
          </p>
          <input type="text" style={{ outline: 0, borderWidth: '0 0 2px', borderColor: 'rgba(0, 0, 0, 0.0001)', boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)', height: 20, width: '100%' }}
            required value={accountStore.phone}
            onChange={(e) => {
              accountStore.setPhone(e.target.value);
            }}/>
        </div>
        <div>
          <p style={{ marginBottom: 0 }}>
            Open AI API Key
          </p>
          <input type="text" style={{ outline: 0, borderWidth: '0 0 2px', borderColor: 'rgba(0, 0, 0, 0.0001)', boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)', height: 20, width: '100%' }}
            value={accountStore.openAIKey} required
            onChange={e => {
              accountStore.setOpenAIKey(e.target.value);
            }}/>
        </div>
        <div>
          <p style={{ marginBottom: 0 }}>
            AYR Share API Key
          </p>
          <input type="text" style={{ outline: 0, borderWidth: '0 0 2px', borderColor: 'rgba(0, 0, 0, 0.0001)', boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)', height: 20, width: '100%' }}
            value={accountStore.ayrKey} required
            onChange={e => {
              accountStore.setAYRKey(e.target.value);
            }}/>
        </div>
      </div>
    </div>
  );
})
export default ProfileForm;