import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import PlanDropdown from '../PlanDropdown/PlanDropdown';
import TimeDropdown from '../TimeDropdown/TimeDropdown';
import CalendarComponent from '../CalendarComponent/CalendarComponent';

const PostMenu = observer(({ items }) => {
  const [planDropdown, setPlanDropdown] = useState(true);
  const [timeDropdown, setTimeDropdown] = useState(true);
  const [calendarDropdown, setCalendarDropdown] = useState(true);

  const togglePlanDropdown = (index) => {
    if (index === 0) {
      setPlanDropdown(!planDropdown);
    } else if (index === 1) {
      setTimeDropdown(!timeDropdown);
    } else {
      setCalendarDropdown(!calendarDropdown);
    }

  };

  const renderedItems = items.map((item, index) => {
    // const activePlanDropdown = index === activeIndex ? 'block' : 'none';
    return (
      <div key={item.title} style={{ background: 'rgba(0,0,0,0.0001)' }}>
        <div onClick={() => togglePlanDropdown(index)} style={{ background: 'rgba(0,0,0,0.0001)', boxShadow: 'inset 0px -1px 0px rgba(0,0,0,0.1)', position: 'relative', textDecoration: 'none', listStyle: 'none', width: '100%', display: 'flex', height: 40, borderTop: '0px solid black' }}>
          <div style={{ display: 'flex', width: 'calc(100% - 40px)', position: 'relative', alignItems: 'center', paddingLeft: 10, paddingRight: 10, cursor: 'pointer', fontFamily: 'nexa_boldregular', fontWeight: 'bold', fontSize: 11 }}>
            <img src={item.image} alt="" style={{ marginRight: 10 }} />
            Select {index === 0 ? "Day" : ""}
            {index === 1 ? "Time" : ""}
            {index === 2 ? "Plan" : ""}
            <span style={{
              position: 'absolute', right: 0, transition: 'transform 0.3s ease',
              transform: (planDropdown === true && index === 0) ||
                (timeDropdown === true && index === 1) ||
                (calendarDropdown === true && index === 2)
                ? 'rotate(180deg)'
                : 'rotate(0deg)'
            }}>
              <img src="./down-arrow.svg" alt="" />
            </span>
          </div>
        </div>
        {planDropdown === true && index === 0 ? <CalendarComponent /> : ""}
        {timeDropdown === true && index === 1 ? <TimeDropdown /> : ""}
        {calendarDropdown === true && index === 2 ? <PlanDropdown /> : ""}
      </div>
    );
  });
  return <div style={{ height: 'calc(100% - 110px)', overflowY: 'auto' }}>{renderedItems}</div>;
});
export default PostMenu;