import { observer } from 'mobx-react';
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { AccountStoreContext } from '../store/AccountStore.js';
import { getFirestore, collection, query, where, getDocs, deleteDoc } from 'firebase/firestore';

let accountSelected = false;
let currentAccount;

const AccountDropdown = observer(() => {
  const accountStore = useContext(AccountStoreContext);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [, setSelectedAccountData] = useState(null);

  const fetchSelectedAccount = useCallback(async () => {
    try {
      const db = getFirestore();
      const q = query(collection(db, "accounts"), where("accountName", "==", currentAccount));
      const querySnapshot = await getDocs(q);
      let selectedAccount = {};
      querySnapshot.forEach((doc) => {
        selectedAccount = { id: doc.id, ...doc.data() };
      });
      setSelectedAccountData(selectedAccount);
      console.log('selectedAccount', selectedAccount);
      accountStore.setKeyword1(selectedAccount.keyword1);
      accountStore.setKeyword2(selectedAccount.keyword2);
      accountStore.setKeyword3(selectedAccount.keyword3);
      accountStore.setAccountName(selectedAccount.accountName);
      accountStore.setStartDate(selectedAccount.startDate);
      accountStore.setFrequency(selectedAccount.daily);
      accountStore.setBackLink(selectedAccount.backlink);
      accountStore.setHashTags(selectedAccount.hashtags);
      accountStore.setTone(selectedAccount.tone);
      accountStore.setDaily(selectedAccount.daily);
      accountStore.setStartTime(selectedAccount.startTime);
      accountStore.setOpenAIKey(selectedAccount.openAIKey);
      accountStore.setAYRKey(selectedAccount.ayrKey);
      accountStore.setRunning(selectedAccount.running);
      accountStore.setTwitter(selectedAccount.twitter);
      accountStore.setInstagram(selectedAccount.instagram);
      accountStore.setFacebook(selectedAccount.facebook);
      accountStore.setLinkedIn(selectedAccount.linkedIn);
      accountStore.setBlog(selectedAccount.blog);
      accountStore.setWebsite(selectedAccount.website);
      accountStore.setEmail(selectedAccount.email);
      accountStore.setPhone(selectedAccount.phone);
      accountStore.setWordCount(selectedAccount.wordCount);
    } catch (error) {
      console.error("Error fetching account data: ", error);
      setSelectedAccount("");
    }
  }, [accountStore]);

  useEffect(() => {
    if (accountStore.selectedAccountData) {
      fetchSelectedAccount();
    }
  }, [accountStore.selectedAccountData, fetchSelectedAccount]);

  const addAccount = () => {
    accountStore.setSelectedAccount("");
    accountStore.setAccountName("");
    accountStore.setOpenAIKey("");
    accountStore.setInstagram(false);
    accountStore.setFacebook(false);
    accountStore.setTwitter(false);
    accountStore.setLinkedIn(false);
    accountStore.setAYRKey("");
    accountStore.setAccountName("");
    accountStore.setShowProfile(!accountStore.showProfile);
    accountSelected = false;
  }

  const editAccount = () => {
    if(accountSelected === false) {
      alert("You must select an account first to edit");
      return;
    }
    accountStore.setShowProfile(!accountStore.showProfile);
    fetchSelectedAccount();
  }

  const deleteAccount = async (accountName) => {
    try {
      console.log(`Account name: ${accountName}`);
      const db = getFirestore();
      const q = query(collection(db, "accounts"), where("accountName", "==", accountName));
      const querySnapshot = await getDocs(q);
      const shouldDelete = window.confirm("Are you sure you want to delete this?");
      if (shouldDelete) {
        querySnapshot.forEach((doc) => {
          deleteDoc(doc.ref);
          console.log(`Deleted account with name: ${accountName}`);
          setTimeout(() => {
            window.location.reload();
          },500)
        });
      }
    } catch (err) {
      console.error(`Error deleting account: ${err}`);
    }
  }

  return(
    <>
      <ul>
      {accountStore.accounts.map((data, index) => {
        return (
          <li key={index} style={{ listStyle: 'none', display: 'flex', alignItems: 'center', color: selectedAccount === index ? '#4C6FFF' : 'initial' }}>
            <input style={{ marginRight: 10, height: 20, width: 20 }}
              type="radio"
              name="account"
              value={accountStore.selectedAccount}
              checked={accountStore.selectedAccount === index}
              onChange={(e) => {
                if (accountStore.selectedAccount === index) {
                  accountStore.setSelectedAccount(null);
                  accountStore.setSelectedAccountData(data);
                  setSelectedAccount(index);
                  accountSelected = true;
                } else {
                  accountStore.setSelectedAccount(index);
                  setSelectedAccount(index);
                  accountStore.setSelectedAccountData(data);
                  accountSelected = true;
                }
                accountStore.setShowProfile(false);
                let pText = e.target.nextSibling.nextSibling.textContent;
                currentAccount = pText;
              }}/>
            <div style={{ backgroundImage: `url(${data.profileImage})`, height: 20, width: 20, borderRadius: '50%', backgroundSize: 'contain', marginRight: 10 }}/>
            <p style={{ fontSize: 12, fontFamily: 'nexa_lightregular', fontWeight: 'bold' }}>
              {data.accountName}
            </p>
            <img src="./trash.svg" style={{ position:'absolute', right: 10, cursor: 'pointer' }} alt=""
              onClick={() => {
                const shouldDelete = window.confirm("Are you sure you want to delete this?");
                if (shouldDelete) {
                  deleteAccount(data.accountName);
                }
              }}/>
          </li>
        );
      })}
      </ul>
      <div onClick={addAccount} style={{ backgroundImage: 'url(./add-account-btn.svg)', height: 30, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', margin: '10px', cursor: 'pointer' }}/>
      <div onClick={editAccount} style={{ fontSize: 12, margin: 'auto', height: 30, borderRadius: 10, width: '100%', maxWidth: 265, display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', position: 'relative', cursor: 'pointer', color: 'white', backgroundColor: 'rgb(76, 111, 255)', marginBottom: 10 }}>
        Edit
      </div>
    </>
  );
})
export default AccountDropdown;