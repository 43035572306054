import React, { useEffect, useState } from 'react';
import styles from './loginbox.module.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

// import logo from './logo.svg';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCJ3yk_HfU0xvzTesJaH_k8jj6Wri6X9tw",
  authDomain: "ding-data-937f9.firebaseapp.com",
  projectId: "ding-data-937f9",
  storageBucket: "ding-data-937f9.appspot.com",
  messagingSenderId: "348072294129",
  appId: "1:348072294129:web:62edd725dfc90fddacdb50",
  measurementId: "G-YLZQ52TNH9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const provider = new GoogleAuthProvider();

const LoginBox = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, redirect to dashboard
        navigate('/dashboard');
      }
      setLoading(false);

    });

    // Clean up subscription on unmount
    return () => unsubscribe();
  }, [auth, navigate]);

  if (loading) {
    return <div>Loading...</div>;  // Replace with your actual loading component
  }


  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token.
        // You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        navigate("/dashboard");
        


        console.log('User:', user);
        console.log('Access Token:', token);
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromResult(error);

        console.error('Error:', errorMessage);
        console.error('Error:', errorCode);
        console.error('Error:', email);
        console.error('Error:', credential);
      });
  };

  return (
    <div className={styles.center}>
      <img src="./logo.svg" style={{position:'absolute', top: -100}} alt="" />
      <p style={{ fontSize: 32, fontFamily: 'nexa_boldregular',}}>Login</p>
      <div style={{height:5, backgroundColor:'#4C6FFF', width: 30, position: 'absolute',top: 79 }}></div>
      <div className={styles.googleLogin} onClick={signInWithGoogle}><img src="./google.svg" alt="" />Login with Google</div>
    </div>
  );
}

export default LoginBox;

