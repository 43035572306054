import React, { useState, useContext } from "react";
import { AccountStoreContext } from '../store/AccountStore';
// import { getFirestore, collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
// import { getAuth } from 'firebase/auth';
let cards = [];
// let imageList = [];


let retrieveTaskDetails = async (robotId, taskId) => {
  const apiKey = ''; // Replace with your actual API key
  const url = ``;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${apiKey}`
      }
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // If successful, parse the JSON response
    const data = await response.json();

    // Use the data as needed. Here, we return it.
    return data;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;  // You might want to handle this differently
  }
};

function Inventory() {
  const accountStore = useContext(AccountStoreContext);

  // State for the text field
  const [text, setText] = useState("");
  // State for the list of texts
  const [list, setList] = useState([]);
  const [isSaved, setIsSaved] = useState(true);
  
  let training = [
    { role: "system", content: `The following will generate blog or social media posts based on data given about a product as well as hashtags that match the post and make the post contain the best SEO friendly keywords as well as in a tone for example tone: funny or tone: sad etc and also return an accurate wordCount which is the number of words in the blog and put it in json objects and wrap the SEO friendly words or words good for SEO in <b> tags and also inside <span> tags with the property "background-color: yellow;" . Also generate titles based on the data. IMPORTANT: make sure to have no more than 150 characters for the text property in textResponse` },
    { role: 'user', content: '{ availability: Confirm Availability, car-info: https://www.keyesmercedes.com/inventory/new-2023-mercedes-benz-gla-gla-250-fwd-sport-utility-w1n4n4gb7pj527774/, color1: Exterior: Blue, color2: Interior: Macchiato Beige, extra-details: <i class="fa fa-question-circle" aria-hidden="true"></i> Details, msrp: MSRP, personalize: Personalize My Deal, price: $41,445, thumbnail: https://vehicle-images.dealerinspire.com/stock-images/thumbnails/large/chrome/db987372f75636a6354dc5fc0ab94136.png, value-my-trade: https://www.keyesmercedes.com/new-vehicles/# }, { availability: Confirm Availability, car-info: https://www.keyesmercedes.com/inventory/new-2023-mercedes-benz-gla-gla-250-fwd-sport-utility-w1n4n4gb3pj529683/, color1: Exterior: White, color2: Interior: Black, extra-details: <i class="fa fa-question-circle" aria-hidden="true"></i> Details, msrp: MSRP, personalize: Personalize My Deal, price: $42,235, thumbnail: https://vehicle-images.dealerinspire.com/stock-images/thumbnails/large/chrome/4b580dec030cdfc03b97df0bf8305f0c.png, value-my-trade: https://www.keyesmercedes.com/new-vehicles/# }, { availability: Confirm Availability, car-info: https://www.keyesmercedes.com/inventory/new-2023-mercedes-benz-gla-gla-250-fwd-sport-utility-w1n4n4gb1pj529438/, color1: Exterior: Black, color2: Interior: Black, extra-details: <i class="fa fa-question-circle" aria-hidden="true"></i> Details, msrp: MSRP, personalize: Personalize My Deal, price: $42,235, thumbnail: https://vehicle-images.dealerinspire.com/stock-images/thumbnails/large/chrome/d3b66aa97a606ce8f56975dc0a1ea3dd.png, value-my-trade: https://www.keyesmercedes.com/new-vehicles/#  }, hound, mut tone:funny' },
    {
        role: 'assistant', content: `{
  "imageResponse": [
    {"image": "mercedes benz gla 250 sport"},
    {"image": "mercedes benz gla 250 sport"},
    {"image": "mercedes benz gla 250 sport"}
  ],
  "textResponse": [
    {
        "text": "Step into a new realm of elegance and performance with the <span style="background-color: yellow;"><b>2023 Mercedes-Benz GLA 250 FWD Sport Utility</b></span>. Featuring a stunning <span style="background-color: yellow;"><b>black exterior</b></span> coupled with a luxurious <span style="background-color: yellow;"><b>black interior</b></span>, this car exemplifies refined sophistication. With its unparalleled safety features, you can expect a smooth and secure ride every time. Priced at <span style="background-color: yellow;"><b>$42,235</b></span>, it’s a small price to pay for a premium driving experience. Hurry and confirm its availability here!",
        "wordCount": "69",
        "hashTags": ["#MercedesGLA2023", "#BlackEleganceOnWheels", "#SophisticatedDrive", "#PremiumSafetyFirst", "#LuxuryRedefined", "#AffordableElegance", "#SecureSmoothRide"]
    },
    {
        "text": "Indulge in the sheer elegance and top-tier performance of the all-new <span style="background-color: yellow;"><b>2023 Mercedes-Benz GLA 250 FWD Sport Utility</b></span>. Its sleek <span style="background-color: yellow;"><b>black exterior</b></span> and luxurious <span style="background-color: yellow;"><b>black interior</b></span> promise a sophisticated driving experience. Equipped with key safety features, it offers an assurance of a safe ride along with the thrill of a high-performing vehicle. Available now for just <span style="background-color: yellow;"><b>$42,235</b></span>. Check its availability here and step up your driving game!",
        "wordCount": "67",
        "hashTags": ["#AllNewGLA250", "#SleekBlackMercedes", "#DriveInStyle2023", "#SafetyMeetsPerformance", "#EleganceOnTheRoad", "#MercedesExperience", "#TopTierDriving"]
    },
    {
        "text": "Embrace the epitome of luxury and power with the <span style="background-color: yellow;"><b>2023 Mercedes-Benz GLA 250 FWD Sport Utility</b></span>. Showcasing a distinguished <span style="background-color: yellow;"><b>black exterior</b></span> and a plush <span style="background-color: yellow;"><b>black interior</b></span>, this vehicle takes elegance and style to a new level. Its state-of-the-art safety features ensure a relaxed, safe journey every time. Grab this luxury ride for just <span style="background-color: yellow;"><b>$42,235</b></span>. Hurry, confirm its availability here. Upgrade your drive with Mercedes-Benz!",
        "wordCount": "64",
        "hashTags": ["#LuxuryMeetsPower", "#2023MercedesElegance", "#DistinguishedDrive", "#SafetyAndStyleCombined", "#MercedesUpgrade", "#EliteOnTheRoad", "#PlushBlackBeauty"]
    } 
  ],
  "title": ["Experience the Luxury of the New 2023 Mercedes-Benz GLA 250 Sport Utility Vehicle", "Embrace Sophistication with the 2023 Mercedes-Benz GLA 250 FWD Sport Utility!", "Experience the Power of Elegance with the 2023 Mercedes-Benz GLA 250 FWD Sport Utility!"]
  }`},
  { role: 'user', content: `${accountStore.keyword1}, ${accountStore.keyword2}, ${accountStore.keyword3}, tone: ${accountStore.tone !== '' ? accountStore.tone : 'blogger' }`},
  ];

//   async function generateImagesFromDescriptions(descriptions) {
//     let imageArr = [];

//     for (let i = 0; i < descriptions.length; i++) {
//         try {
//             // console.log(data);

//             // // Checking if data exists, it is an array, and the 'i' index exists in the data array
//             //     imageArr.push(data.data[0].url);
       
//         } catch (err) {
//             console.error(err);
//         }
//     }

//     return imageArr;
// }

  

  async function getData(training) {
    try {
        const response = await fetch("https://us-central1-flowroom-fd862.cloudfunctions.net/customChat", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                conversation: training,
            }),
        });
  
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const chatText = await response.text();
        const chatObject = JSON.parse(chatText);
  
        // Add the assistant's response to the conversationHistory
        training.push({
            role: "assistant",
            content: JSON.stringify(chatText),
        });
  
        return chatObject;
    } catch (error) {
        console.error(error);
        throw error;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add the current text to the list
    setList((prevList) => [...prevList, text]);
    // Clear the text field
    setText("");
  };

  return (
    <div style={{    marginTop: 25,
      marginLeft: 30, background:'white', left:10}}>
      <form onSubmit={handleSubmit}>
        <div style={{display:'flex', flexDirection:'column'}}>
        <input
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          style={{
            background: 'rgb(245, 245, 245)',
            borderRadius: 8,
            border: '0px solid',
            height: 30,
            margin: '5px 7px',
            maxWidth: 200,
            paddingLeft: 10
          }}
        />
        <div style={{width:200, backgroundColor:'white'}}>
        <button style={{height: 30,
  width: 100,
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 10,
  borderRadius: 10,
  marginLeft: 10,
  marginTop: 10,
  cursor: 'pointer',
  fontFamily: 'nexa_boldregular',
  fontSize:14, border:'none'}} type="submit"               className="button"
  >Add to list</button></div>
        <ul style={{height: 50,
    overflowY: 'scroll',
    listStyle: 'none',
    background: 'rgb(238 238 238)',
    borderRadius: 10,
    width: '100%',
    maxWidth: 200, paddingLeft: 10,
    paddingTop: 10, position:'relative', left:10 }}>
        {list.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
        <div style={{display:'flex', marginLeft:10}}>

<div style={{
height: 30,
  width: 70,
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 10,
  borderRadius: 10,
  marginLeft: 10,
  marginTop: 10,
  cursor: 'pointer',
  fontFamily: 'nexa_boldregular',
  fontSize:16,
  
}} 

              className="button"

onClick={async () => {
  // if (isSaved) {
  //   return;
  // }
  if (!accountStore || !accountStore.selectedAccount) {
    alert("You must select an account!");
    return;
  }
  setIsSaved(false);

  // const auth = getAuth();
  // const user = auth.currentUser;
  // const db = getFirestore();
  // const q = query(collection(db, "accounts"), where("accountName", "==", accountStore.accountName));
  // const querySnapshot = await getDocs(q);
  // let accounts = [];
  // querySnapshot.forEach((doc) => {
  // const account = doc.data();
  //   accounts.push(account);
  // });
  // console.log("User:", user);
  // console.log("Account data:", accountStore.keyword1, accountStore.keyword2, accountStore.keyword3);
  // if (user) {
  //   const q = query(collection(db, "accounts"), where("accountName", "==", accountStore.accountName));
  //   const querySnapshot = await getDocs(q);
  //   if (!querySnapshot.empty) {
  //     const docData = querySnapshot.docs[0]; 
  //     const accountDoc = doc(db, "accounts", docData.id);
  //     console.log("Updating document:", accountDoc);
  //     console.log("Start Time:", accountStore.startTime);
  //       try {
  //         await updateDoc(accountDoc, {
  //           keyword1: accountStore.keyword1,
  //           keyword2: accountStore.keyword2,
  //           keyword3: accountStore.keyword3,
  //           daily: accountStore.daily,
  //           startDate: accountStore.startDate,
  //           backlink: accountStore.backlink,
  //           hashtags: accountStore.hashtags,
  //           tone: accountStore.tone,
  //           startTime: accountStore.startTime
  //         });
  //         console.log("Update successful");
  //       } catch (error) {
  //       console.error("Error updating document:", error);
  //     }
  //   } else {
  //     console.error("No document found with accountName:", user.accountName);
  //   }
  // }
  setTimeout(()=>{
  setIsSaved(true);
  },1000)

}}>
  {isSaved ? 'Save' : 'Saving...'}
</div>  
<div style={{
  height: 30,
  width: 70,
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 10,
  borderRadius: 10,
  marginLeft: 10,
  marginTop: 10,
  cursor: 'pointer',
  fontFamily: 'nexa_boldregular'
}}
className="button"
onClick={async ()=> {
  if(accountStore.running === false) {
  // accountStore.setRunning(true);

  // const auth = getAuth();
  // const user = auth.currentUser;
  // const db = getFirestore();
  // const q = query(collection(db, "accounts"), where("accountName", "==", accountStore.accountName));
  // const querySnapshot = await getDocs(q);
  // let accounts = [];
  // querySnapshot.forEach((doc) => {
  // const account = doc.data();
  //   accounts.push(account);
  // });
  // console.log("User:", user);
  // console.log("Account data:", accountStore.keyword1, accountStore.keyword2, accountStore.keyword3);
  // if (user) {
  //   const q = query(collection(db, "accounts"), where("accountName", "==", accountStore.accountName));
  //   const querySnapshot = await getDocs(q);
  //   if (!querySnapshot.empty) {
  //     const docData = querySnapshot.docs[0]; 
  //     const accountDoc = doc(db, "accounts", docData.id);
  //     console.log("Updating document:", accountDoc);
  //     console.log("Start Time:", accountStore.startTime);
  //       try {
  //         await updateDoc(accountDoc, {
  //           running: accountStore.running
  //         });
  //         console.log("Update successful");

  //         if (!isSaved) {
  //            return;
  //       }
  let images = await retrieveTaskDetails('035ad425-c7d0-4ffc-a631-50e20d17cdac', '29c9a65d-075e-43be-b52c-d1b23045b9c2');
  
  console.log('key data', images.result.capturedLists.keyes);
     let generate = images.result.capturedLists.keyes;
     let generate1 = `{ availability: ${generate[0].availability}, car-info: ${generate[0]['car-info']}, color1: ${generate[0].color1}, color2: ${generate[0].color2}, extra-details: ${generate[0]['extra-details']}, msrp: ${generate[0].msrp}, personalize: ${generate[0].personalize}, price: ${generate[0].price}, thumbnail: ${generate[0].thumbnail}, value-my-trade: ${generate[0]['value-my-trade']}`
     let generate2 = `{ availability: ${generate[1].availability}, car-info: ${generate[1]['car-info']}, color1: ${generate[1].color1}, color2: ${generate[1].color2}, extra-details: ${generate[1]['extra-details']}, msrp: ${generate[1].msrp}, personalize: ${generate[1].personalize}, price: ${generate[1].price}, thumbnail: ${generate[1].thumbnail}, value-my-trade: ${generate[1]['value-my-trade']}`
     let generate3 = `{ availability: ${generate[2].availability}, car-info: ${generate[2]['car-info']}, color1: ${generate[2].color1}, color2: ${generate[2].color2}, extra-details: ${generate[2]['extra-details']}, msrp: ${generate[2].msrp}, personalize: ${generate[2].personalize}, price: ${generate[2].price}, thumbnail: ${generate[2].thumbnail}, value-my-trade: ${generate[2]['value-my-trade']}`
 
     accountStore.setKeyword1(generate1);
     accountStore.setKeyword2(generate2);
     accountStore.setKeyword3(generate3);


     console.log(`${accountStore.keyword1}, ${accountStore.keyword2}, ${accountStore.keyword3}`)
     accountStore.setGeneratingContent(true);
     accountStore.setHamburgerOpen(false);


      let result = await getData(training);
      console.log(result);
      cards = [];
    for(let i = 0; i < result.textResponse.length; i ++) {
      let obj = {};
      obj.text = result.textResponse[i].text;
      obj.title = result.title[i];
      obj.hashtags = result.textResponse[i].hashTags;
      obj.wordCount = result.textResponse[i].wordCount;
      cards.push(obj);
     }
    try {
      console.log('cards', cards);

      for(let m = 0; m < 3; m++) {
        cards[m].image = images.result.capturedLists.keyes[m].thumbnail;
      }
      accountStore.setCards([]);
      accountStore.setCards(cards); // set the cards in accountStore
      accountStore.setInitialImage(cards[0].image);
      accountStore.setInitialText(cards[0].text);
      accountStore.setGeneratingContent(false);

  
   
  // } catch (err) {
  //   console.error(err);
  // }



  //  cards.push(obj);
  //  accountStore.setCards(cards); // set the cards in accountStore


  } catch (error) {
    console.error("Failed to get data", error);
  }
    //     } catch (error) {
    //     console.error("Error updating document:", error);
    //   }
    // } else {
    //   console.error("No document found with accountName:", user.accountName);
     } else {
      accountStore.setRunning(false);
      accountStore.setHamburgerOpen(false);

     }
//   }
// } else {
//   accountStore.setRunning(false);
//   const auth = getAuth();
//   const user = auth.currentUser;
//   const db = getFirestore();
//   const q = query(collection(db, "accounts"), where("accountName", "==", accountStore.accountName));
//     const querySnapshot = await getDocs(q);
//     if (!querySnapshot.empty) {
//       const docData = querySnapshot.docs[0]; 
//       const accountDoc = doc(db, "accounts", docData.id);
//       console.log("Updating document:", accountDoc);
//       console.log("Start Time:", accountStore.startTime);
//         try {
//           await updateDoc(accountDoc, {
//             running: accountStore.running
//           });
//           console.log("Update successful");
//           accountStore.setRunning(false);
//           if (!isSaved) {
//              return;
//         }
//       } catch {}


    
//   }

//}
console.log("Inventory Run");
}}>{accountStore.running === true ? 'Pause' : 'Run'}</div>
</div>
</div>
      </form>
    
    </div>
  );
}

export default Inventory;
